<!-- 库存明细 -->

<template>
  <div class="inventoryList">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="typeValue" placeholder="选择类型" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="productCodeSearch" placeholder="搜索商品条形码" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-input v-model="productNameSearch" placeholder="搜索商品名称" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="productNum" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="inventoryType" label="类型">
            <template slot-scope="scope">
              {{ scope.row.inventoryType == 1 ? '入库' : '出库' }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家/商超"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="beforeCount" label="变动前数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="changeCount" label="变动数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="afterCount" label="库存数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="时间"
            width="180"></el-table-column>

        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      productCodeSearch: "", // 搜索商品编号
      productNameSearch: "", // 搜索商品名称
      dataValue: "", // 选择时间
      // 下拉框数据 选择类型
      typeValue: '',
      options: [{
        value: 2,
        label: '出库'
      }, {
        value: 1,
        label: '入库'
      },],
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    };
  },
  created() {
    this.getInventoryDetailsList();
  },
  methods: {
    // 获取列表
    getInventoryDetailsList() {
      this.loading = true;
      let params = {
        inventoryType: this.typeValue || null, // 类型
        // productNum: this.productCodeSearch || null, // 搜索商品编号
        barCode: this.productCodeSearch || null, // 搜索商品条形码
        productName: this.productNameSearch || null, // 搜索商品名称
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间（开始时间-结束时间）
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0];
        params.endTime = this.dataValue[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }

      this.$axios.get(this.$api.getInventoryDetailsList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 查看
    handleCheck() {
      this.$router.push('detail/leaveListDetail')
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 时间搜索
    handleSelectData(value) {
      this.clearData();
      this.dataValue = value;
      this.getInventoryDetailsList();
    },
    // 下拉选择类型
    handleSelectType(value) {
      this.clearData();
      this.typeValue = value;
      this.getInventoryDetailsList();
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getInventoryDetailsList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryDetailsList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryDetailsList();
    },
  },
};
</script>

<style scoped lang="scss">
.inventoryList {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
